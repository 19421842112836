/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/primeng.min.css";
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";

html {
  font-size: 1rem;
  height: 100%;
}

body {
  font-family: var(--font-family);
  background-color: var(--surface-ground);
  color: var(--text-color);
  padding: 0;
  margin: 5;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
